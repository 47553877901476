import React, {useContext} from 'react';
import styled from 'styled-components';
import {AppContext} from "../../../../context/AppContext";
import {BREAK_POINT_MEDIUM} from "../../../constants/constants";
import {WebConfigV2} from "../../../../config/web.config.v2";
import { SectionTitle } from '../styles/CommonStyles';

const PoolStatisticSection: React.FC = () => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error();
  }
  
  const {tokenStatistic} = appContext;
  
  const poolInfo: {
    title: string;
    mainValue: string;
    subValue?: string;
    hasInfo?: boolean;
  }[] = [
    {
      title: `${WebConfigV2.Content.statistic_section.item_price_title}`,
      mainValue: `${tokenStatistic.priceBNB} BNB`,
      subValue: `$${tokenStatistic.priceUSD}`
    },
    {
      title: `${WebConfigV2.Content.statistic_section.item_total_supply_title}`,
      mainValue: `${tokenStatistic.totalSupply}`,
    },
    {
      title: `${WebConfigV2.Content.statistic_section.item_circulating_supply_title}`,
      mainValue: `${tokenStatistic.circulatingSupply}`,
    },
    {
      title: `${WebConfigV2.Content.statistic_section.item_total_staked_title}`,
      mainValue: `${tokenStatistic.totalStakedBNB} BNB`,
      subValue: `$${tokenStatistic.totalStakedUSD}`
    },
    {
      title: `${WebConfigV2.Content.statistic_section.item_total_Loans_title}`,
      mainValue: `${tokenStatistic.totalLoans} ${WebConfigV2.ProjectInfo.token_symbol}`,
      subValue: `$${tokenStatistic.totalLoansUSD}`
    },
    {
      title: `${WebConfigV2.Content.statistic_section.item_tvl_title}`,
      mainValue: `${tokenStatistic.tvlBNB} BNB`,
      subValue: `$${tokenStatistic.tvlUSD}`
    }
  ];
  
  return (
    <Section>
      <SectionTitle>Pool Statistics</SectionTitle>
      <Container>
        <CardsContainer>
          {poolInfo.map((info, index) => (
            <Card key={index}>
              <CardHeader>
                {info.hasInfo && <InfoIcon />}
              </CardHeader>
              <CardTitle>{info.title}</CardTitle>
              <MainValue>{info.mainValue}</MainValue>
              {info.subValue && <SubValue>{info.subValue}</SubValue>}
            </Card>
          ))}
        </CardsContainer>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  width: 100%;
  padding: 0 8%;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background: radial-gradient(
    circle at center,
    #5e55a040 0%,
    transparent 100%
  );
  backdrop-filter: blur(12px);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 24px;
  position: relative;
  border: 1px solid rgba(51, 65, 85, 0.9);
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-4px);
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  background: linear-gradient(to right, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 
    0 0 10px rgba(255, 215, 0, 0.3),
    0 0 20px rgba(255, 215, 0, 0.2);
  transition: all 0.3s ease;
  letter-spacing: 0.5px;

  ${Card}:hover & {
    text-shadow: 
      0 0 15px rgba(255, 215, 0, 0.4),
      0 0 30px rgba(255, 215, 0, 0.3);
  }
`;

const InfoIconWrapper = styled.div`
  color: rgba(255, 255, 255, 0.5);
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  background: linear-gradient(to right, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
  transition: all 0.3s ease;

  ${Card}:hover & {
    text-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
  }
`;

const MainValue = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: #a78bfa;
  margin-bottom: 4px;
  text-shadow: 0 0 10px rgba(167, 139, 250, 0.3);
  transition: all 0.3s ease;

  ${Card}:hover & {
    text-shadow: 0 0 15px rgba(167, 139, 250, 0.4);
  }
`;

const SubValue = styled.div`
  font-size: 14px;
  color: #22c55e;
  opacity: 0.9;
  text-shadow: 0 0 8px rgba(34, 197, 94, 0.3);
  transition: all 0.3s ease;

  ${Card}:hover & {
    opacity: 1;
    text-shadow: 0 0 12px rgba(34, 197, 94, 0.4);
  }
`;

const InfoIcon = () => (
  <InfoIconWrapper>
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" y1="16" x2="12" y2="12" />
      <line x1="12" y1="8" x2="12.01" y2="8" />
    </svg>
  </InfoIconWrapper>
);

export default PoolStatisticSection;