import React, {ChangeEvent, useContext, useEffect} from "react";
import styled from "styled-components";
import {AppContext} from "../../../../../context/AppContext";
import {formatTime} from "../../../../../helper/time.helper";
import {
  showErrorToast,
  showProcessingToast,
  updateErrorToast,
  updateSuccessToast
} from "../../../../../helper/ToastHelper";
import {Web3ServiceIns} from "../../../../../service/Web3Service";
import {BREAK_POINT_SMALL} from "../../../../constants/constants";
import {WebConfigV2} from "../../../../../config/web.config.v2";
import {ethers} from "ethers";

export const LoanComponent: React.FC = () => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error();
  }
  
  const {minningInfo} = appContext;
  
  const [stopLoanText, setStopLoanText] = React.useState<string>(`${WebConfigV2.Content.action_section.action_Loan.unstake_button}`);
  const [stopLoanEnable, setStopLoanEnable] = React.useState<boolean>(false);
  const [stopLoanTimeout, setStopLoanTimeout] = React.useState<NodeJS.Timeout | undefined>(undefined);
  const [stakeValue_t, setStakeValue_t] = React.useState<string>('0.0');
  const [stakeValueWei_t, setStakeValueWei_t] = React.useState<bigint>(BigInt(0));
  
  const handleCheckTimeStopLoan = (timeSeconds: number) => {
    if (timeSeconds > 0) {
      const timeFormat = formatTime(timeSeconds);
      setStopLoanText(timeFormat);
      const nStopLoanTimeout = setTimeout(handleCheckTimeStopLoan.bind(null, timeSeconds - 1), 1000);
      setStopLoanTimeout(nStopLoanTimeout);
      setStopLoanEnable(false);
    } else {
      setStopLoanText(`${WebConfigV2.Content.action_section.action_Loan.unstake_button}`);
      setStopLoanEnable(true);
    }
  }
  
  useEffect(() => {
    if (stopLoanTimeout) {
      clearTimeout(stopLoanTimeout);
    }
    
    handleCheckTimeStopLoan(minningInfo.timeStopLoan);
  }, [minningInfo, stopLoanTimeout]);
  
  const handleClickMax = () => {
    setStakeValue_t(minningInfo.userTokenBalance);
    setStakeValueWei_t(minningInfo.userTokenBalanceWei);
    console.log(minningInfo.userTokenBalanceWei);
  }
  
  const handleChangeStakeValue_t = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setStakeValue_t(value);
    
    const valueWei = ethers.parseEther(value.toString());
    setStakeValueWei_t(valueWei);
  }
  
  const handleStake_t = async () => {
    try {
      showProcessingToast('stake_t', WebConfigV2.Content.toast.stake_token.processing);
      await Web3ServiceIns.stake_t(stakeValueWei_t);
      setStakeValue_t('0.0');
      updateSuccessToast('stake_t', WebConfigV2.Content.toast.stake_token.success);
    } catch (e: any) {
      updateErrorToast('stake_t', WebConfigV2.Content.toast.stake_token.error + e.reason);
    }
  }
  
  const handleUnstake_t = async () => {
    if (!stopLoanEnable) {
      showErrorToast('unstake_t', 'Your unstaking period has not yet arrived');
      return;
    }
    
    try {
      showProcessingToast('unstake_t', WebConfigV2.Content.toast.unstake_token.processing);
      await Web3ServiceIns.stop_t();
      updateSuccessToast('unstake_t', WebConfigV2.Content.toast.unstake_token.success);
    } catch (e: any) {
      updateErrorToast('unstake_t', WebConfigV2.Content.toast.unstake_token.error + e.reason);
    }
  }
  
  const handleClaim_t = async () => {
    try {
      showProcessingToast('claim_t', WebConfigV2.Content.toast.claim_token.processing);
      await Web3ServiceIns.claimMinning_t();
      updateSuccessToast('claim_t', WebConfigV2.Content.toast.claim_token.success);
    } catch (e: any) {
      updateErrorToast('claim_t', WebConfigV2.Content.toast.claim_token.error + e.reason);
    }
  }
  
  const handleExtendTime = async (pkg: any) => {
    try {
      showProcessingToast('extend_time', WebConfigV2.Content.toast.extend.processing);
      await Web3ServiceIns.extendStakePeriod(pkg);
      updateSuccessToast('extend_time', WebConfigV2.Content.toast.extend.success);
    } catch (e: any) {
      updateErrorToast('extend_time', WebConfigV2.Content.toast.extend.error + e.reason);
    }
  }
  
  return (
    <>
      <StatsGrid>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_Loan.apr_title}</StatLabel>
          <StatValue>{`${minningInfo.arp_t}%`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_Loan.total_stake_title}</StatLabel>
          <StatValue>{`${minningInfo.totalStaked_t} ${WebConfigV2.ProjectInfo.token_symbol}`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_Loan.my_stake_title}</StatLabel>
          <StatValue>{`${minningInfo.userStaked_t} ${WebConfigV2.ProjectInfo.token_symbol}`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_Loan.my_balance_title}</StatLabel>
          <StatValue>{`${minningInfo.userTokenBalance} ${WebConfigV2.ProjectInfo.token_symbol}`}</StatValue>
        </StatItem>
      </StatsGrid>
      
      <TimeStatusArea>
        <div className="titleArea">
          <span className="title">Time remaining</span>
          {
            (minningInfo.totalDay - minningInfo.stakeDay) <= 0 ? (
              <span className="timeTitle">Complete</span>
            ) : (
              <span className="timeTitle">{minningInfo.totalDay - minningInfo.stakeDay} days</span>
            )
          }
        
        </div>
        <ProgressBar>
          <Progress width={(minningInfo.stakeDay/minningInfo.totalDay) * 100} />
        </ProgressBar>
      </TimeStatusArea>
      
      <div className="mb-6">
        <PackageTitleArea>
          <h3 className="text-lg text-white font-semibold text-center">Extend Staking Time</h3>
        </PackageTitleArea>
        
        <PackagesGrid>
          {minningInfo.extendPkgs.map((pkg) => (
            <PackageCard key={pkg.days} onClick={() => handleExtendTime(pkg)}>
              <div className="pkgInfo">
                <p className="font-bold mb-2 text-white">{Number(pkg.days)} Days</p>
                <p className="text-blue-400 priceInfo">{Number(Number(ethers.formatEther(pkg.amount)).toFixed(3)).toLocaleString()}eBTC</p>
              </div>
              <SubButton>Extend</SubButton>
            </PackageCard>
          ))}
        </PackagesGrid>
      </div>
      
      <ClaimRow>
        <ClaimInfo>
          <StatLabel>{WebConfigV2.Content.action_section.action_Loan.claim_available_title}</StatLabel>
          <StatValue>{`${minningInfo.userMinned_t} ${WebConfigV2.ProjectInfo.token_symbol}`}</StatValue>
        </ClaimInfo>
        <SubButton onClick={handleClaim_t}>{WebConfigV2.Content.action_section.action_Loan.claim_button}</SubButton>
      </ClaimRow>
      
      <InputGroup>
        <InputLabel>
          <span>{WebConfigV2.Content.action_section.action_Loan.stake_amount_title}</span>
          <span>{`Balance: ${minningInfo.userTokenBalance} ${WebConfigV2.ProjectInfo.token_symbol}`}</span>
        </InputLabel>
        <Input>
          <TokenInput placeholder="0.0" type="number" value={stakeValue_t} onChange={handleChangeStakeValue_t}/>
          <MaxButton onClick={handleClickMax}>MAX</MaxButton>
        </Input>
      </InputGroup>
      <ActionButtonWrapper>
        <ActionButton onClick={handleStake_t}>{WebConfigV2.Content.action_section.action_Loan.stake_button}</ActionButton>
        <UnstakeButton onClick={handleUnstake_t}>
          <SubButtonInner>
            <SubButtonOverlay>
              <SubButtonText>{stopLoanText}</SubButtonText>
            </SubButtonOverlay>
          </SubButtonInner>
        </UnstakeButton>
      </ActionButtonWrapper>
    </>
  )
}

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 32px;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
`;

const StatItem = styled.div`
  //background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StatLabel = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
`;

const StatValue = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    font-size: 18px;
  };
`;

const TimeStatusArea = styled.div`
  background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 20px;
  
  .titleArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .title {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
  }
  
  .timeTitle {
    color: white;
    font-size: 20px;
    font-weight: 600;
    @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
      font-size: 16px;
    };
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: #515151;
    border-radius: 0.75rem;;
  overflow: hidden;
`;

const Progress = styled.div<{ width: number }>`
  height: 100%;
  width: ${props => props.width}%;
  background: linear-gradient(to right, #60a5fa, #a855f7);
    border-radius: 0.75rem;;
  transition: width 0.3s ease;
`;

const PackageTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const PackagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    grid-template-columns: repeat(1, 1fr);
  };
`;

const PackageCard = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
  align-items: center;

  &:hover {
    background-color: #374151;
  }
  
  .pkgInfo {
    margin-bottom: 16px;
  }
  
  .priceInfo {
    text-align: center;
  }

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    flex-direction: row;
    justify-content: space-between;

    .pkgInfo {
      margin-bottom: 0;
      align-items: start;
    }

    .priceInfo {
      text-align: left;
    }
  };
`;

const InputGroup = styled.div`
  margin-bottom: 24px;
`;

const InputLabel = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

const Input = styled.div`
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TokenInput = styled.input`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 18px;
  outline: none;
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const MaxButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  padding: 8px 16px;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 215, 0, 0.8);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }
`;

const ClaimRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 24px;
`;

const ClaimInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`

const ActionButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  padding: 16px;
  border-radius: 0.75rem;
  font-size: 18px;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    font-size: 14px;
  };
`;

const SubButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);  
  border: none;
  color: #000;
  padding: 12px 24px;
  border-radius: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    font-size: 12px;
  };
`;

const UnstakeButton = styled.div`
  border-radius: 0.75rem;
  width: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  } 
`;

const SubButtonInner = styled.div`
  padding: 1px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

const SubButtonOverlay = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background: linear-gradient(to top, #FFD700, #FFED4A);
  align-items: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
`

const SubButtonText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    font-size: 14px;
  };
`