import React, {ChangeEvent, useContext} from "react";
import styled from "styled-components";
import {AppContext} from "../../../../../context/AppContext";
import {showProcessingToast, updateErrorToast, updateSuccessToast} from "../../../../../helper/ToastHelper";
import {Web3ServiceIns} from "../../../../../service/Web3Service";
import {BREAK_POINT_SMALL} from "../../../../constants/constants";
import {WebConfigV2} from "../../../../../config/web.config.v2";
import {ethers} from "ethers";

export const StakeComponent: React.FC = () => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error();
  }
  
  const {minningInfo} = appContext;
  
  const [stakeValue_m, setStakeValue_m] = React.useState<string>('0.0');
  const [stakeValueWei_m, setStakeValueWei_m] = React.useState<bigint>(BigInt(0));
  
  const handleChangeStakeValue_m = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setStakeValue_m(value);
    
    const valueWei = parseFloat(value) > 0 ? ethers.parseEther(value.toString()) : BigInt(0);
    setStakeValueWei_m(valueWei);
  }
  
  const handleClickMax = () => {
    setStakeValue_m(minningInfo.userBalance);
    setStakeValueWei_m(minningInfo.userBalanceWei);
  }
  
  const handleStake_m = async () => {
    try {
      showProcessingToast('stake_m', WebConfigV2.Content.toast.stake_bnb.processing);
      
      await Web3ServiceIns.stake_m(stakeValueWei_m);
      setStakeValue_m('0');
      
      updateSuccessToast('stake_m', WebConfigV2.Content.toast.stake_bnb.success);
    } catch (e: any) {
      updateErrorToast('stake_m', WebConfigV2.Content.toast.stake_bnb.error + e.reason);
    }
  }
  
  const handleClaim_m = async () => {
    try {
      showProcessingToast('claim_m', WebConfigV2.Content.toast.claim_token.processing);
      await Web3ServiceIns.claimMinning_m();
      updateSuccessToast('claim_m', WebConfigV2.Content.toast.claim_token.success);
    } catch (e: any) {
      updateErrorToast('claim_m', WebConfigV2.Content.toast.claim_token.error + e.reason);
    }
  }
  
  const handleExtendTime = async (pkg: any) => {
    try {
      showProcessingToast('extend_time', WebConfigV2.Content.toast.extend.processing);
      await Web3ServiceIns.extendStakePeriod(pkg);
      updateSuccessToast('extend_time', WebConfigV2.Content.toast.extend.success);
    } catch (e: any) {
      updateErrorToast('extend_time', WebConfigV2.Content.toast.extend.error + e.reason);
    }
  }
  
  
  return (
    <>
      <StatsGrid>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_stake.apr_title}</StatLabel>
          <StatValue>{`${minningInfo.arp_m}%`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_stake.total_stake_title}</StatLabel>
          <StatValue>{`${minningInfo.totalStaked_m} BNB`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_stake.my_stake_title}</StatLabel>
          <StatValue>{`${minningInfo.userStaked_m} BNB`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_stake.my_balance_title}</StatLabel>
          <StatValue>{`${minningInfo.userBalance} BNB`}</StatValue>
        </StatItem>
      </StatsGrid>
      
      <TimeStatusArea>
        <div className="titleArea">
          <span className="title">Time remaining</span>
          {
            (minningInfo.totalDay - minningInfo.stakeDay) <= 0 ? (
              <span className="timeTitle">Complete</span>
            ) : (
              <span className="timeTitle">{minningInfo.totalDay - minningInfo.stakeDay} days</span>
            )
          }
        
        </div>
        <ProgressBar>
          <Progress width={(minningInfo.stakeDay/minningInfo.totalDay) * 100} />
        </ProgressBar>
      </TimeStatusArea>
      
      <div className="mb-6">
        <PackageTitleArea>
          <h3 className="text-lg text-white font-semibold text-center">Extend Staking Time</h3>
        </PackageTitleArea>
        
        <PackagesGrid>
          {minningInfo.extendPkgs.map((pkg) => (
            <PackageCard key={pkg.days} onClick={() => handleExtendTime(pkg)}>
              <div className="pkgInfo">
                <p className="font-bold mb-2 text-white">{Number(pkg.days)} Days</p>
                <p className="text-blue-400 priceInfo">{Number(Number(ethers.formatEther(pkg.amount)).toFixed(3)).toLocaleString()} {WebConfigV2.ProjectInfo.extend_token_symbol}</p>
              </div>
              <SubButton>Extend</SubButton>
            </PackageCard>
          ))}
        </PackagesGrid>
      </div>
      
      <ClaimRow>
        <ClaimInfo>
          <StatLabel>{WebConfigV2.Content.action_section.action_stake.claim_available_title}</StatLabel>
          <StatValue>{`${minningInfo.userMinned_m} ${WebConfigV2.ProjectInfo.token_symbol}`}</StatValue>
        </ClaimInfo>
        <ClaimButton onClick={handleClaim_m}>{WebConfigV2.Content.action_section.action_stake.claim_button}</ClaimButton>
      </ClaimRow>
      
      <InputGroup>
        <InputLabel>
          <span>{WebConfigV2.Content.action_section.action_stake.stake_amount_title}</span>
          <span>{`Balance: ${minningInfo.userBalance} BNB`}</span>
        </InputLabel>
        <Input>
          <TokenInput placeholder="0.0" type="number" value={stakeValue_m} onChange={handleChangeStakeValue_m}/>
          <MaxButton onClick={handleClickMax}>MAX</MaxButton>
        </Input>
      </InputGroup>
      
      <StakeButton onClick={handleStake_m}>{WebConfigV2.Content.action_section.action_stake.stake_button}</StakeButton>
    </>
  )
}

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 32px;
  
  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
`;

const StatItem = styled.div`
  //background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StatLabel = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
`;

const StatValue = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    font-size: 18px;
  };
`;

const TimeStatusArea = styled.div`
  background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 20px;
  
  .titleArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .title {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
  }
  
  .timeTitle {
    color: white;
    font-size: 20px;
    font-weight: 600;
    @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
      font-size: 16px;
    };
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: #515151;
    border-radius: 0.75rem;;
  overflow: hidden;
`;

const Progress = styled.div<{ width: number }>`
  height: 100%;
  width: ${props => props.width}%;
  background: linear-gradient(to right, #60a5fa, #a855f7);
    border-radius: 0.75rem;;
  transition: width 0.3s ease;
`;

const PackageTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const PackagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    grid-template-columns: repeat(1, 1fr);
  };
`;

const PackageCard = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
  align-items: center;

  &:hover {
    background-color: #374151;
  }
  
  .pkgInfo {
    margin-bottom: 16px;
  }
  
  .priceInfo {
    text-align: center;
  }

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    flex-direction: row;
    justify-content: space-between;

    .pkgInfo {
      margin-bottom: 0;
      align-items: start;
    }

    .priceInfo {
      text-align: left;
    }
  };
`;

const InputGroup = styled.div`
  margin-bottom: 24px;
`;

const InputLabel = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

const Input = styled.div`
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
`;

const TokenInput = styled.input`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 18px;
  outline: none;
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const MaxButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  padding: 8px 16px;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 215, 0, 0.8);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }
`;

const ClaimRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 24px;
`;

const ClaimInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ClaimButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  padding: 12px 24px;
  border-radius: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }
`;

const StakeButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  padding: 16px;
  border-radius: 0.75rem;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    font-size: 14px;
  };
`;

const SubButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);  
  border: none;
  color: #000;
  padding: 12px 24px;
  border-radius: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    font-size: 12px;
  };
`;
