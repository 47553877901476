import {AppContextType} from "../context/AppContext";
import {CONTRACT_ABI, TOKEN_ABI} from "../config/contract-config";
import {ethers} from "ethers";
import {NumberFormatter} from "../helper/number.helper";
import axios from "axios";
import {MinningInfo} from "../models/MinningInfo";
import {RefInfo} from "../models/RefInfo";
import {WebConfigV2} from "../config/web.config.v2";

const SERVICE_FEE = ethers.parseEther('0.00014783');

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export interface IWeb3Service {
  setContext(context: AppContextType): void;
  setWalletAddress(address: string | undefined): void;
  setWalletProvider(provider: any): void;
  
  loadTokenData(): Promise<void>;
  
  addMetamask(): Promise<boolean>;
  
  stake_m(amountWei: bigint): Promise<void>;
  stake_t(amountWei: bigint): Promise<void>;
  stop_t(): Promise<void>;
  claimMinning_m(): Promise<void>;
  claimMinning_t(): Promise<void>;
  sellToken(amount: number): Promise<void>;
  withdrawRef(): Promise<void>;
  
  convertBNBAmount(tokenAmount: string): Promise<string>;
}

export class Web3Service implements IWeb3Service {
  private context: AppContextType | undefined;
  
  private provider: any | undefined;
  private contractAddress: string;
  private contractABI: ethers.InterfaceAbi;
  private contract: ethers.Contract | undefined;
  
  private bnbPrice: number;
  private walletAddress: string | undefined;
  
  private fee = ethers.parseEther('0.00014783');
  
  constructor() {
    this.contractAddress = WebConfigV2.ProjectInfo.contract_address;
    this.contractABI = CONTRACT_ABI;
    
    this.bnbPrice = 0;
    this.walletAddress = ethers.ZeroAddress;
    this.initialize();
  }
  
  initialize() {
    this.provider = new ethers.JsonRpcProvider(WebConfigV2.ProjectInfo.rpc);
    this.contract = new ethers.Contract(this.contractAddress, this.contractABI, this.provider);
  }
  
  setContext(context: AppContextType) {
    this.context = context;
  }
  
  async setWalletAddress(address: string| undefined) {
    this.walletAddress = address || ethers.ZeroAddress;
    await this._loadMinningInfo();
    await this._loadReferralInfo();
  }
  
  async setWalletProvider(provider: any) {
    if (!provider) {
      this.provider = new ethers.JsonRpcProvider(WebConfigV2.ProjectInfo.rpc);
      this.contract = new ethers.Contract(this.contractAddress, this.contractABI, provider);
    }
    else {
      const ethersProvider = new ethers.BrowserProvider(provider);
      this.provider = ethersProvider;
      const signer = await ethersProvider.getSigner()
      this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer);
    }
    
    await this._loadTokenStatistics();
    await this._loadMinningInfo();
    await this._loadReferralInfo();
  }
  
  async loadTokenData(): Promise<void> {
    this.bnbPrice = await this._getBNBPrice();
    await this._loadTokenStatistics();
    await this._loadMinningInfo();
    await this._loadReferralInfo();
    
    setTimeout(() => {
      this.loadTokenData();
    }, 30000);
  }
  
  async _loadTokenStatistics(): Promise<void> {
    if (!this.contract || !this.context) {
      return;
    }
    
    try {
      const [totalStakedBNB, totalTokenLoan] = await this.contract.getGlobalInfo2();
      const tokenPriceBNB = await this.contract.getTokenPrice();
      const totalSupply = await this.contract.limitSupply();
      const circulatingSupply = await this.contract.totalSupply();
      const availableSupply = await this.contract.availableSupply();
      // const totalStakedBNB = await this.contract.totalStaked();
      // const totalTokenLoan = await this.contract.totalTokenStaked();
      const totalLoanBNB = await this.contract.tokenTo(totalTokenLoan);
      const tvlBNB = await this.contract.getContractBalance();
      
      const fmPriceBNB = NumberFormatter(ethers.formatEther(tokenPriceBNB), 8);
      const fmPriceUSD = NumberFormatter((Number(fmPriceBNB) * this.bnbPrice).toString(), 4);
      
      const fmTotalStakedBNB = NumberFormatter(ethers.formatEther(totalStakedBNB));
      const fmTotalStakedUSD = NumberFormatter((Number(fmTotalStakedBNB) * this.bnbPrice).toString(), 2);
      
      const fmTotalTokenLoan = NumberFormatter(ethers.formatEther(totalTokenLoan));
      const fmTotalLoanBNB = NumberFormatter(ethers.formatEther(totalLoanBNB));
      const fmTotalLoanUSD = NumberFormatter((Number(fmTotalLoanBNB) * this.bnbPrice).toString(), 2);
      
      const fmTvlBNB = NumberFormatter(ethers.formatEther(tvlBNB));
      const fmTvlUSD = NumberFormatter((Number(fmTvlBNB) * this.bnbPrice).toString(), 2);
      
      const {setTokenStatistic} = this.context;
      setTokenStatistic(oldValue => {
        const newStatistic = {
          ...oldValue,
          priceBNB: fmPriceBNB,
          priceUSD: fmPriceUSD.toString(),
          totalSupply: NumberFormatter(ethers.formatEther(totalSupply)),
          circulatingSupply: NumberFormatter(ethers.formatEther(circulatingSupply)),
          availableSupply: NumberFormatter(ethers.formatEther(availableSupply)),
          totalStakedBNB: fmTotalStakedBNB,
          totalStakedUSD: fmTotalStakedUSD,
          totalLoans: fmTotalTokenLoan,
          totalLoansUSD: fmTotalLoanUSD,
          tvlBNB: fmTvlBNB,
          tvlUSD: fmTvlUSD,
        }
        return newStatistic;
      });
    } catch (e) {
      console.error(e);
    }
  }
  
  async _loadMinningInfo(): Promise<void> {
    if (!this.contract || !this.context) {
      return;
    }
    
    try {
      const [apy_m, apy_t, totalStaked, totalTokenStaked, sellLimit, availableToday, tokenSoldToday, timeToNextDay] = await this.contract.getGlobalInfo2();
      let [userStaked, userTokenStaked, timeToStop, userUnClaimed_m, userUnClaimed_t, userTokenBalance, startTS, endTS] = await this.contract.getUserInfo(this.walletAddress);
      const contractExtendPkgs = await this.contract.getExtendStakePackage() as [];
      const extendPkgs = [];
      for (let i = 0; i < contractExtendPkgs.length; i++) {
        extendPkgs.push({
          days: contractExtendPkgs[i],
          amount: contractExtendPkgs[i+1],
        });
        i++;
      }
      
      // HM - Test
      // const extendPkgs = (await this._testGetExtend() as []).map(pkg => ({
      //   days: pkg[0] as number,
      //   amount: pkg[1] as number,
      // }));
      const userBalance = this.walletAddress !== ethers.ZeroAddress ? await this.provider.getBalance(this.walletAddress) : BigInt(0);
      const fmUserBalance = NumberFormatter(ethers.formatEther(userBalance), 6);
      
      const fmTotalStaked_m = NumberFormatter(ethers.formatEther(totalStaked));
      const fmTotalStaked_t = NumberFormatter(ethers.formatEther(totalTokenStaked));
      
      const fmUserStake_m = NumberFormatter(ethers.formatEther(userStaked));
      const fmUserStake_t = NumberFormatter(ethers.formatEther(userTokenStaked));
      const fmUserMinned_m = NumberFormatter(ethers.formatEther(userUnClaimed_m), 6);
      const fmUserMinned_t = NumberFormatter(ethers.formatEther(userUnClaimed_t), 6);
      const fmUserTokenBalance = NumberFormatter(ethers.formatEther(userTokenBalance), 6);
      
      const fmSoldToday = NumberFormatter(ethers.formatEther(tokenSoldToday), 4);
      const fmAvailableToday = NumberFormatter(ethers.formatEther(availableToday), 4);
      
      // HM - test
      const totalDay = startTS > 0 ? Math.floor((Number(endTS) - Number(startTS)) / 60) : 0;
      const stakeDay = startTS > 0 ? Math.floor((Date.now()/1000 - Number(startTS)) / 60) : 0;
      console.log(userUnClaimed_m, userUnClaimed_t);
      
      const {setMinningInfo} = this.context;
      const newMinningInfo = <MinningInfo>{
        arp_m: apy_m.toString(),
        arp_t: apy_t.toString(),
        totalStaked_m: fmTotalStaked_m,
        totalStaked_t: fmTotalStaked_t,
        userStaked_m: fmUserStake_m,
        userStaked_t: fmUserStake_t,
        userMinned_m: fmUserMinned_m,
        userMinned_t: fmUserMinned_t,
        userBalance: fmUserBalance,
        userTokenBalance: fmUserTokenBalance,
        userBalanceWei: userBalance,
        userTokenBalanceWei: userTokenBalance,
        soldToday: fmSoldToday,
        availableToday: fmAvailableToday,
        timeReset: Number(timeToNextDay),
        timeStopLoan: Number(timeToStop),
        
        startTS: startTS,
        endTS: endTS,
        stakeDay: stakeDay,
        totalDay: totalDay,
        extendPkgs: extendPkgs,
      };
      setMinningInfo(newMinningInfo);
    } catch (e) {
      console.error(e);
    }
  }
  
  async _loadReferralInfo(): Promise<void> {
    if (!this.contract || !this.context || !this.walletAddress) {
      return;
    }
    
    try {
      const [refBonus, refTotalBonus, refWithdraw] = await this.contract.getUserRefInfo(this.walletAddress);
      const [lv1Count, lv2Count, lv3Count, lv4Count, lv5Count] = await this.contract.getUserDownlineCount(this.walletAddress);
      
      const fmRefBonus = NumberFormatter(ethers.formatEther(refBonus), 4);
      const fmRefTotalBonus = NumberFormatter(ethers.formatEther(refTotalBonus), 4);
      const fmRefWithdraw = NumberFormatter(ethers.formatEther(refWithdraw), 4);
      
      const totalRef = Number(lv1Count + lv2Count + lv3Count + lv4Count + lv5Count);
      
      const {setRefInfo} = this.context;
      const refInfo = <RefInfo>{
        earnAvailable: fmRefBonus,
        totalEarned: fmRefTotalBonus,
        totalWithdraw: fmRefWithdraw,
        totalReferral: totalRef,
      }
      setRefInfo(refInfo);
    } catch (e) {
      console.error(e);
    }
  }
  
  async addMetamask(): Promise<boolean> {
    if (typeof window.ethereum !== 'undefined') {
      console.log("Metamask is installed")
    } else {
      console.log("Metamask is not installed")
      return false;
    }
    
    try {
      // @ts-ignore
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: 'ERC20',
          options: {
            address: WebConfigV2.ProjectInfo.contract_address,
            symbol: WebConfigV2.ProjectInfo.token_symbol,
            decimals: 18,
            image: WebConfigV2.ProjectInfo.token_image
          }
        }
      });
      
      return wasAdded;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  
  async stake_m(amountWei: bigint): Promise<void> {
    if (!this.contract) {
      throw {
        reason: "Unknown error",
      }
    }
    
    const urlObj = new URL(window.location.href);
    const refValue = urlObj.searchParams.get('ref');
    const transactionResponse = await this.contract.stake(refValue || ethers.ZeroAddress, {
      value: amountWei,
    });
    await transactionResponse.wait();
    console.log("Stake_m complete: ", transactionResponse.hash);
    await this._loadTokenStatistics();
    await this._loadMinningInfo();
  }
  
  async stake_t(amountWei: bigint): Promise<void> {
    if (!this.contract) {
      throw {
        reason: "Unknown error",
      }
    }
    
    const transactionResponse = await this.contract.stakeToken(amountWei, {
      value: SERVICE_FEE,
    });
    await transactionResponse.wait();
    console.log("Stake_t complete: ", transactionResponse.hash);
    await this._loadTokenStatistics();
    await this._loadMinningInfo();
  }
  
  async stop_t(): Promise<void> {
    if (!this.contract) {
      throw {
        reason: "Unknown error",
      }
    }
    
    const transactionResponse = await this.contract.unStakeToken({
      value: SERVICE_FEE,
    });
    await transactionResponse.wait();
    console.log("Unstake_t complete: ", transactionResponse.hash);
    await this._loadTokenStatistics();
    await this._loadMinningInfo();
  }
  
  async claimMinning_m(): Promise<void> {
    if (!this.contract) {
      throw {
        reason: "Unknown error",
      }
    }
    
    const transactionResponse = await this.contract.claimToken_M({
      value: SERVICE_FEE,
    });
    await transactionResponse.wait();
    console.log("Claim_m complete: ", transactionResponse.hash);
    await this._loadTokenStatistics();
    await this._loadMinningInfo();
  }
  
  async claimMinning_t(): Promise<void> {
    if (!this.contract) {
      throw {
        reason: "Unknown error",
      }
    }
    
    const transactionResponse = await this.contract.claimToken_T({
      value: SERVICE_FEE,
    });
    await transactionResponse.wait();
    console.log("Claim_t complete: ", transactionResponse.hash);
    await this._loadTokenStatistics();
    await this._loadMinningInfo();
  }
  
  async sellToken(amount: number): Promise<void> {
    if (!this.contract) {
      throw {
        reason: "Unknown error",
      }
    }
    
    const amountWei = ethers.parseEther(amount.toString());
    const transactionResponse = await this.contract.sellToken(amountWei, {
      value: SERVICE_FEE,
    });
    await transactionResponse.wait();
    console.log("Sell token complete: ", transactionResponse.hash);
    await this._loadTokenStatistics();
    await this._loadMinningInfo();
  }
  
  async withdrawRef(): Promise<void> {
    if (!this.contract) {
      throw {
        reason: "Unknown error",
      }
    }
    
    const transactionResponse = await this.contract.withdrawRef({
      value: SERVICE_FEE,
    });
    await transactionResponse.wait();
    console.log("Withdraw ref complete: ", transactionResponse.hash);
    await this._loadTokenStatistics();
    await this._loadMinningInfo();
    await this._loadReferralInfo();
  }
  
  async extendStakePeriod(pkg: any): Promise<void> {
    if (!this.contract) {
      throw {
        reason: "Unknown error",
      }
    }
    
    await this.approveExtendToken(pkg.amount);
    
    const transactionResponse = await this.contract.extendStakeTime(pkg.days, {
      value: SERVICE_FEE,
    });
    await transactionResponse.wait();
    console.log("Extend stake time complete: ", transactionResponse.hash);
    await this._loadMinningInfo();
  }
  
  async approveExtendToken(amountWei: bigint): Promise<void> {
    if (!this.contract) {
      throw {
        reason: "Unknown error",
      }
    }
    
    const signer = await this.provider.getSigner();
    const tokenAddress = await this.contract.getExtendTokenAddress();
    const tokenContract = new ethers.Contract(tokenAddress, TOKEN_ABI, signer);
    const allowance = await tokenContract.allowance(this.walletAddress, this.contractAddress);
    if (allowance > amountWei) {
      return;
    }
    
    await tokenContract.approve(this.contractAddress, ethers.parseEther("1000000000000"));
    await delay(3000);
  }
  
  async convertBNBAmount(tokenAmount: string): Promise<string> {
    if (!this.contract || !this.context) {
      return '0.0000';
    }
    
    try {
      const tokenAmountWei = ethers.parseEther(tokenAmount);
      const bnbAmountWei = await this.contract.tokenTo(tokenAmountWei);
      const bnbAmount = NumberFormatter(ethers.formatEther(bnbAmountWei), 5);
      return bnbAmount;
    } catch (e) {
      console.error(e);
      return '0.0000';
    }
  }
  
  async _getBNBPrice(): Promise<number> {
    try {
      const result = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
        params: {
          ids: "binancecoin",
          vs_currencies: "usd"
        }
      });
      
      if (result.status !== 200) {
        return 0;
      }
      
      const tokenPriceUSD = result.data.binancecoin.usd;
      return tokenPriceUSD;
    } catch (e) {
      console.error(e);
      return 0;
    }
  }
}

export const Web3ServiceIns = new Web3Service();