// *** Note:
// RPC mainnet: https://bsc-dataseed3.defibit.io
// RPC testnet: https://data-seed-prebsc-1-s1.binance.org:8545/

const ProjectInfo = {
  "token_name": "WeBTC",
  "token_symbol": "WeBTC",
  "extend_token_symbol": "eBTC",
  "token_image": "https://explorer.extremebtc.io/b-1.png",
  "contract_address": "0xbb170611dcf6960d09ac48fa341ea9330b42c30e",
  "rpc": "https://bsc-dataseed3.defibit.io/"
}

const WalletConnectInfo = {
  "name": "Test",
  "description": 'AppKit Example',
  "url": 'https://reown.com/appkit', // origin must match your domain & subdomain
  "icons": ['https://assets.reown.com/reown-profile-pic.png'],
  "project_id": "97dde16ab81df7fb4e803553959e003f"
}

const Content = {
  "nav_section": {
    "app_logo": "app_logo.png",
    "home_title": "Home",
    "stake_title": "WeBTC Mining",
    "referral_title": "EBTC Mining",
    "document_title": "Document",
    "document_link": "https://docs.extremebtc.io",
  },
  "banner_section": {
    "title": "WeBTC",
    "subtitle": "Future Of DeFi",
    "desc": "Advanced protocol based on Proof-of-Activity",
    "button_1": "Stake Now ->",
    "button_2": "Quick Guide",
  },
  "token_info_section": {
    "title": "Wrapped eBTC (WeBTC)",
    "infos": [
      {
        "title": "Symbol",
        "desc": ProjectInfo.token_symbol,
        "highlight": true,
      },
      {
        "title": "Token Address",
        "desc": `${ProjectInfo.contract_address.slice(0, 10)}...${ProjectInfo.contract_address.slice(-6)}`,
        "highlight": false,
      },
      {
        "title": "Chain Info",
        "desc": "BEP-20. BRC-20(Comming)",
        "highlight": false,
      }
    ]
  },
  "staking_info_section": {
    "title": " Lightning node with BNB Stake",
    "subtitle": `Stake your ⚡️BNB to receive ⚡️WeBTC`,
    "highlight_text": "WeBTC",
    "sub_text": "®️",
    "desc": "Power and Time Based on BNB ➕ eBTC",
    "button": "Stake Now",
  },
  "key_feature_section": {
    "title": "Key Features of BNB Staking",
    "features": [
      {
        title: "Lightning node",
        description: "Stake BNB to get Lightning node power⚡️Stake eBTC to get Lightning node mining time💧"
      },
      {
        title: "Wrapped eBTC",
        description: "Wrapped eBTC (WeBTC) is a wrapped form of eBTC created during the Lightning node run. 🔥90% of eBTC will be burned to create WeBTC"
      },
      {
        title: "Quick Node Web3 APIs ",
        description: "Native blockchain applications are similar to smart contracts but are scalable and interoperable on their own blockchain."
      },
      {
        title: "Read & write to the blockchain",
        description: "Unparalleled latency, reliability and scalability across all blockchains. Long term value strategy for📈  eBTC. It's more than technology — it's your legacy🔑"
      },
      {
        title: "High APY Returns",
        description: "Earn competitive profits through ⛏ Quick Node Web3 APIs. Profit pooling option for maximum returns."
      },
      {
        title: "Automated Mining",
        description: "Mining power scales with your staked amount. No additional equipment needed."
      }
    ]
  },
  "statistic_section": {
    "title": "Pool Statistics",
    "item_price_title": `$${ProjectInfo.token_symbol} Price`,
    "item_total_supply_title": "Total Supply",
    "item_circulating_supply_title": "Circulating Supply",
    "item_available_supply_title": "Available Supply",
    "item_total_staked_title": "Total $BNB Staked",
    "item_total_Loans_title": `Total ${ProjectInfo.token_symbol} Loans`,
    "item_tvl_title": "TVL"
  },
  "action_section": {
    "title": " Quick Node Web3 APIs",
    "subtitle": `Stake $BNB and earn $${ProjectInfo.token_symbol} while still accruing interest.`,
    "tabbar": [
      {
        "tab_title": "Stake",
        "help": "" +
          "• Mining WeBTC by staking your BNB.\n" +
          "• There is no limit on the amount of deposits. The bigger the stake, the faster the mining.\n" +
          "• You cannot cancel your BNB stake. The BNB stake guarantees the intrinsic value of WeBTC even if it is not yet recognized.",
      },
      {
        "tab_title": "Loan",
        "help": "" +
          "• Preeminent features that no other cryptocurrency has.\n" +
          "• Maximum Assets that you can sell currently 3 WeBTC\n" +
          "• You can only sell your property once per day at any time.",
      },
      {
        "tab_title": "Sell",
        "help": "" +
          "• Preeminent features that no other cryptocurrency has.\n" +
          "• Maximum Assets that you can sell currently 3 WeBTC\n" +
          "• You can only sell your property once per day at any time.",
      }
    ],
    "action_stake": {
      "apr_title": "APR",
      "total_stake_title": "Total Staked",
      "my_stake_title": "My Staked",
      "my_balance_title": "My Balance",
      "claim_available_title": "Claim Available",
      "stake_amount_title": "Stake Amount",
      "claim_button": "Claim",
      "stake_button": "Stake"
    },
    "action_Loan": {
      "apr_title": "APR",
      "total_stake_title": "Total Staked",
      "my_stake_title": "My Staked",
      "my_balance_title": "My Balance",
      "claim_available_title": "Claim Available",
      "stake_amount_title": "Stake Amount",
      "claim_button": "Claim",
      "stake_button": "Stake",
      "unstake_button": "Stop Loans"
    },
    "action_sell": {
      "sold_today_title": "Sold Today",
      "available_today_title": "Available Today",
      "reset_in_title": "Reset In",
      "sell_amount_title": "Sell Amount",
      "will_receive_title": "You will receive",
      "sell_button": "Sell",
    }
  },
  "referral_section": {
    "title": "Referral Program",
    "referral_info": {
      "referral_link_placeholder": "You will get your ref link after investing",
      "copy_button_title": "Copy Link",
      "referral_earn_title": "Referral Earned Available",
      "referral_total_earn_title": "Total Referral Earned",
      "referral_total_withdraw_title": "Total Referral Withdraw",
      "referral_total_title": "Total Referrals",
      "widthdraw_button_title": "Withdraw referral rewards"
    },
    "referral_explain": {
      "title": "Referral Program Rules",
      "explains": [
        "You will receive:",
        "• 8% from each level 1 referral deposits\n" +
        "• 4% from each level 2 referral deposits\n" +
        "• 3% from each level 3 referral deposits\n" +
        "• 2% from each level 4 referral deposits\n" +
        "• 2% from each level 5 referral deposits\n" +
        "• 2% from each level 6 referral deposits\n" +
        "• 2% from each level 7 referral deposits\n" +
        "• 1% from each level 8-15 referral deposits",
        "Deposit atleast once to activate Referral Rewards",
      ],
    }
  },
  "partner_section": {
    partners: [
      {
        name: 'tradingview',
        logo: '/partners/OKX.webp',
        url: 'https://www.okx.com/vi/web3/detail/56/0xfdb380a44c2ee8a31e74ce0d94777b324c729801'
      },
      {
        name: 'coinmarketfees',
        logo: '/partners/CAKE.png',
        url: 'https://pancakeswap.finance/?outputCurrency=0xfdB380A44C2Ee8A31e74ce0D94777b324c729801'
      },
      {
        name: 'coinbase',
        logo: '/partners/MATCHA.webp',
        url: 'https://matcha.xyz/tokens/bsc/0xfdb380a44c2ee8a31e74ce0d94777b324c729801?sellChain=56&sellAddress=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&sellAmount=1'
      },
      {
        name: 'bscscan',
        logo: '/partners/bscscan.png',
        url: 'https://bscscan.com/address/0xf83b66e901fc35b400b603e5abbb7463a8d88f60#tokentxns'
      },
      {
        name: 'coinmarketcap',
        logo: '/partners/OKXSCAN.png',
        url: 'https://www.okx.com/vi/web3/explorer/bsc/token/0xfdb380a44c2ee8a31e74ce0d94777b324c729801?address=0xf83b66e901fc35b400b603e5abbb7463a8d88f60'
      },
      {
        name: 'binance',
        logo: '/partners/APE.png',
        url: 'https://apespace.io/bsc/0xfdb380a44c2ee8a31e74ce0d94777b324c729801'
      }
    ],
  },
  "toast": {
    "stake_bnb": {
      "processing": "Staking...",
      "success": "Stake successfully!",
      "error": "Stake failed. Reason: "
    },
    "stake_token": {
      "processing": "Staking...",
      "success": "Stake successfully!",
      "error": "Stake failed. Reason: "
    },
    "unstake_token": {
      "processing": "Stopping Loan...",
      "success": "Stop Loan successfully!",
      "error": "Stop Loan failed. Reason: "
    },
    "claim_token": {
      "processing": "Claiming...",
      "success": "Claim token successfully!",
      "error": "Claim token failed. Reason: "
    },
    "sell_token": {
      "processing": "Selling...",
      "success": "Sell token successfully!",
      "error": "Sell token failed. Reason: "
    },
    "withdraw": {
      "processing": "Withdrawing...",
      "success": "Withdraw ref token successfully!",
      "error": "Withdraw ref token failed. Reason: "
    },
    "extend": {
      "processing": "Extending...",
      "success": "Extend stake period successfully!",
      "error": "Extend stake period failed. Reason: "
    }
  }
}

export const WebConfigV2 = {
  ProjectInfo,
  WalletConnectInfo,
  Content,
}

export const CONFIG = {
  APP_NAME: 'WeBTC',
  APP_DESCRIPTION: 'Wrapped eBTC - The First Decentralized Bitcoin on Ethereum',
  TOKEN: {
    NAME: 'WeBTC',
    SYMBOL: 'WeBTC',
  },
}